import { FC } from 'react';
import { Button } from '@arco-design/web-react';
import classNames from 'classnames';
import { IconMagicPencil } from '@arco-iconbox/react-hkgai-government-icon';
import useLocale from '@/hooks/useLocale';

interface BtnAskAiProp {
  btnAskAIClick?: () => void;
}

const Index: FC<BtnAskAiProp> = ({ btnAskAIClick }) => {
  const locale = useLocale();

  return (
    <>
      <Button
        className={classNames('btn-ask-ai')}
        icon={<IconMagicPencil color="#FFFFFF" />}
        onClick={e => {
          btnAskAIClick?.();
          e.stopPropagation();
        }}
      >
        {locale.sidebar_ai_writing}
      </Button>
    </>
  );
};

export default Index;
