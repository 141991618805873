import { Button, Form, Input, Modal, Rate } from '@arco-design/web-react';
import { FC, useEffect, useState } from 'react';
import useLocale from '@/hooks/useLocale';
import './index.scss';

const Index: FC<{ visiable: boolean; onCancel?: () => void; onOk?: (formValues?: any) => void }> = ({ visiable, onCancel, onOk }) => {
  const [form] = Form.useForm();
  const FormItem = Form.Item;
  const locale = useLocale();
  const [feedBackModalIsConfirm, setFeedBackModalIsConfirm] = useState(false);
  const rateOne: string = Form.useWatch('one', form);
  const rateTwo: string = Form.useWatch('two', form);
  const rateThree: string = Form.useWatch('three', form);
  const rateRemark: string = Form.useWatch('remark', form);

  useEffect(() => {
    if (rateOne || rateTwo || rateThree || rateRemark) {
      setFeedBackModalIsConfirm(true);
    } else {
      setFeedBackModalIsConfirm(false);
    }
  }, [rateOne, rateTwo, rateThree, rateRemark]);

  const reportConfirm = (formValues: any) => {
    Modal.confirm({
      title: locale['privacy.modal.report.title'],
      // eslint-disable-next-line react/no-danger
      content: <div style={{ width: '400px' }} dangerouslySetInnerHTML={{ __html: locale['privacy.modal.report.content'] }}></div>,
      okText: locale['privacy.modal.report.submit'],
      cancelText: locale['privacy.modal.report.cancel'],
      // onCancel: () => {
      //    onCancel?.();
      // },
      onOk: async () => {
        try {
          onOk?.(formValues);
        } catch (error) {}
      },
    });
  };

  return (
    <div>
      <Modal
        className="generate-feedback-modal"
        footer={null}
        title={null}
        visible={visiable}
        onCancel={() => {
          onCancel?.();
        }}
        afterClose={() => form.resetFields()}
        maskClosable={false}
      >
        <div className="generate-title"> {locale['ai.writing.generate.feedback.modal.title']}</div>
        <Form className="generate-form" form={form} autoComplete="off" size="large" labelAlign="right">
          <FormItem
            label={locale['ai.writing.generate.feedback.modal.lable.one']}
            field={'one'}
            rules={[
              {
                // required: true,
                message: locale['ai.writing.generate.feedback.modal.lable.errtip'],
              },
            ]}
          >
            <Rate allowClear />
          </FormItem>

          <FormItem
            validateStatus="error"
            label={locale['ai.writing.generate.feedback.modal.lable.two']}
            field={'two'}
            rules={[
              {
                // required: true,
                message: locale['ai.writing.generate.feedback.modal.lable.errtip'],
              },
            ]}
          >
            <Rate allowClear />
          </FormItem>

          <FormItem
            label={locale['ai.writing.generate.feedback.modal.lable.three']}
            field={'three'}
            rules={[
              {
                // required: true,
                message: locale['ai.writing.generate.feedback.modal.lable.errtip'],
              },
            ]}
          >
            <Rate allowClear />
          </FormItem>
          <Form.Item field="remark">
            <Input.TextArea placeholder={locale['report.modal.content.placeholder']} />
          </Form.Item>
        </Form>

        <Button
          className="generate-button"
          type={feedBackModalIsConfirm ? 'primary' : 'secondary'}
          disabled={!feedBackModalIsConfirm}
          onClick={async () => {
            try {
              const formValues = await form.validate();
              if (formValues.remark) {
                reportConfirm(formValues);
              } else {
                onOk?.(formValues);
              }
            } catch (error) {}
          }}
        >
          {locale['ai.writing.generate.feedback.modal.confirm']}
        </Button>
      </Modal>
    </div>
  );
};

export default Index;
