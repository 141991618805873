import { model } from '@modern-js/runtime/model';
import { WritingType } from '@/type/ui';

const persistancePrefix = 'prompt_history_';

const getPersistanceKey = () => {
  const userId = localStorage.getItem('USER_ID');
  return `${persistancePrefix}_${userId}`;
};
interface Props {
  writingPromptHistory: Array<string>;
  toolbarPromptHistory: Partial<Record<WritingType, Array<string>>>;
}

const PromptHistoryStore = model<Props>('PromptHistoryStore').define((context, { use, onMount }) => {
  onMount(() => {
    const [, , subscribePromptHistory] = use(PromptHistoryStore);
    subscribePromptHistory(() => {
      const currentState = use(PromptHistoryStore)[0];
      try {
        Promise.resolve().then(() => {
          localStorage.setItem(getPersistanceKey(), JSON.stringify(currentState));
        });
      } catch (err) {
        console.error('[prompt history]', err);
      }
    });
  });
  return {
    state: {
      writingPromptHistory: [],
      toolbarPromptHistory: {},
    },
    actions: {
      addAIWritingPromptHistory(draft, data) {
        const history = draft.writingPromptHistory.filter(item => item !== data);
        history.unshift(data);
        draft.writingPromptHistory = history;
      },
      removeAIWritingPromptHistory(draft, data) {
        const history = draft.writingPromptHistory.filter(item => item !== data);
        draft.writingPromptHistory = history;
      },
      addtoolbarPromptHistory(draft, data) {
        const writingType = data.writingType as WritingType;
        const { prompt } = data;
        const history = draft.toolbarPromptHistory[writingType]?.filter?.(item => item !== prompt) || [];
        history.unshift(prompt);
        draft.toolbarPromptHistory = {
          ...draft.toolbarPromptHistory,
          [writingType]: history,
        };
      },
      removeToolbarPromptHistory(draft, data) {
        const writingType = data.writingType as WritingType;
        const { prompt } = data;
        const history = draft.toolbarPromptHistory[writingType]?.filter?.(item => item !== prompt) || [];
        draft.toolbarPromptHistory = {
          ...draft.toolbarPromptHistory,
          [writingType]: history,
        };
      },
      setState(draft, data) {
        return data;
      },
    },
    computed: {
      // fileList: state => {
      //   return (state?.resources?.length && state.resources.filter(item => item.type === ResourceType.FILE)) || [];
      // },
      // references: state => {
      //   return (state?.resources?.length && state.resources.filter(item => item.type === ResourceType.ATTACHMENT)) || [];
      // },
    },
    effects: {
      initialize() {
        try {
          const userId = localStorage.getItem('USER_ID');
          const promptHistory = JSON.parse(localStorage.getItem(`${persistancePrefix}_${userId}`) ?? '{}');
          const toolbarPromptHistory = promptHistory.toolbarPromptHistory || {};
          let writingPromptHistory = promptHistory.writingPromptHistory || [];
          if (!Array.isArray(writingPromptHistory)) {
            writingPromptHistory = [];
          }
          for (const writingType in toolbarPromptHistory) {
            if (!Array.isArray(toolbarPromptHistory[writingType])) {
              toolbarPromptHistory[writingType] = [];
            }
          }
          const [, actions] = use(PromptHistoryStore);
          actions.setState({ writingPromptHistory, toolbarPromptHistory });
        } catch (err) {
          console.error('[prompt history]', err);
        } finally {
          console.debug('[prompt history]', 'initialized');
        }
      },
    },
  };
});

export default PromptHistoryStore;
