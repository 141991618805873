import { Trigger, Menu, Tooltip } from '@arco-design/web-react';
import { IconCheck, IconDown } from '@arco-design/web-react/icon';
import { FC, ReactNode, useMemo, useState } from 'react';
import classNames from 'classnames';
import { ALIGN_KEY, HEADING_KEY, LINE_HEIGHT_CUSTOM_KEY, ORDERED_LIST_KEY, PARAGRAPH_KEY, UNORDERED_LIST_KEY } from '@hkgai/slate-plugin/dist/lib/plugins';
import styles from './index.module.scss';
import { MenuItemOptions, SelectedMarks } from '@/type/toolsbar';
import useLocale from '@/hooks/useLocale';

const TriggrtMenu: FC<{
  children: ReactNode;
  menuKey: string;
  options: MenuItemOptions;
  onSelect: (key: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  selectedMarks: SelectedMarks;
  menuWidth?: number;
  disabled?: boolean;
}> = ({ options, children, menuKey, onSelect, selectedMarks, menuWidth, disabled = false }) => {
  const locale = useLocale();
  const { commonConfig } = options;
  const [visible, setVisible] = useState(false);

  const defaultSelectedKeys = useMemo(() => {
    if (menuKey === ALIGN_KEY) {
      return [`${menuKey}.${selectedMarks[menuKey]}`];
    }
    if (menuKey === HEADING_KEY) {
      if (selectedMarks?.[menuKey]?.type) {
        return [`${menuKey}.${selectedMarks[menuKey]?.type}`];
      }
      if (selectedMarks?.[UNORDERED_LIST_KEY]) {
        return [UNORDERED_LIST_KEY];
      }
      if (!selectedMarks?.[ORDERED_LIST_KEY]) {
        return [PARAGRAPH_KEY];
      }
      return [];
    }
    if (menuKey === LINE_HEIGHT_CUSTOM_KEY) {
      return [`${menuKey}.${selectedMarks[menuKey] || 1.5}`];
    }
    return [];
  }, [selectedMarks, menuKey]);

  return (
    <Trigger
      popup={() => (
        <Menu
          style={{ width: menuWidth }}
          className={styles.triggrtMenu}
          onClickMenuItem={(key: string, event: any) => onSelect(key, event)}
          onMouseDown={e => e.preventDefault()}
          defaultSelectedKeys={defaultSelectedKeys}
        >
          {commonConfig?.map(item => (
            <Menu.Item key={item.key}>
              <IconCheck className={styles.checkIcon} />
              {item.icon}
              {menuKey === LINE_HEIGHT_CUSTOM_KEY ? item.value : locale[item.key]}
            </Menu.Item>
          ))}
        </Menu>
      )}
      trigger="hover"
      popupAlign={{
        bottom: 8,
      }}
      position="bottom"
      popupVisible={visible}
      onVisibleChange={v => setVisible(v)}
      disabled={disabled}
    >
      <Tooltip content={locale[menuKey]} disabled={!locale[menuKey] || disabled}>
        <div className={classNames(styles.triggrtMenuContainer, { [styles.triggrtMenuContainerActive]: visible, [styles.triggrtMenuContainerDisabled]: disabled })}>
          <div className={styles.triggrtMenuInner}>
            {children} <IconDown className={styles.iconDown} />
          </div>
        </div>
      </Tooltip>
    </Trigger>
  );
};
export default TriggrtMenu;
