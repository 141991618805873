import './index.scss';

import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { useModel } from '@modern-js/runtime/model';
import { useLocation } from '@modern-js/runtime/router';
import { debounce } from 'lodash-es';
import { BaseNode, Editor, ReactEditor } from '@hkgai/slate-plugin/dist/lib/delta';
import { Editable, EDITOR_EVENT, useMakeEditor, LOG_LEVEL } from '@hkgai/slate-plugin/dist/lib/core';
import {
  BoldPlugin,
  StrikeThroughPlugin,
  ItalicPlugin,
  UnderLinePlugin,
  ClipboardPlugin,
  DividingLinePlugin,
  HeadingPlugin,
  HyperLinkPlugin,
  InlineCodePlugin,
  OrderedListPlugin,
  ParagraphPlugin,
  QuoteBlockPlugin,
  UnorderedListPlugin,
  ProofreadLinePlugin,
  AlignPlugin,
  FontBasePlugin,
  StyleSettingPlugin,
  FontSettingPlugin,
  ImagePlugin,
  TableCustomPlugin,
  LineHeightCustomPlugin,
  ClearFormatPlugin,
} from '@hkgai/slate-plugin/dist/lib/plugins';
import { getEditorContent, getNewParagraphNode } from '../../util';
import { schema } from './schema';
import { useWritingContext } from '@/context/writingContext';
import EditorStore from '@/store/editorStore';
import useLocale from '@/hooks/useLocale';
// import { SelectionBar } from '@/components/selection-bar';

export const NullNode: BaseNode[] = [getNewParagraphNode('')];

export interface DocEditorRef {
  resetEditorContent: () => void;
}

interface DocEditorProp {
  // eslint-disable-next-line react/no-unused-prop-types
  cCId?: string;
}

// eslint-disable-next-line no-empty-pattern
const DocEditor = forwardRef<DocEditorRef, DocEditorProp>(({}, ref) => {
  const locale = useLocale();
  const location = useLocation();
  const { setEditor, setEditorKit, setEditableRef } = useWritingContext();

  const [{ editorReadonly, editorContent, isAIWork }, { setEditorContent }] = useModel(EditorStore);

  const editableRef = useRef<HTMLDivElement>(null);
  const editor = useMakeEditor(schema, NullNode);

  useMemo(() => {
    editor.logger.set(LOG_LEVEL.DEBUG);
    editor.plugin.register(
      new BoldPlugin(editor),
      // new IndentPlugin(editor),
      new StrikeThroughPlugin(editor),
      new ItalicPlugin(editor),
      new UnderLinePlugin(editor),
      new ParagraphPlugin(editor),
      new ClipboardPlugin(editor),
      new HeadingPlugin(editor),
      new QuoteBlockPlugin(editor),
      new HyperLinkPlugin(editor, false),
      new InlineCodePlugin(editor),
      new OrderedListPlugin(editor),
      new UnorderedListPlugin(editor),
      new DividingLinePlugin(editor),
      new TableCustomPlugin(editor, false),
      new ProofreadLinePlugin(editor),
      new AlignPlugin(editor),
      new UnorderedListPlugin(editor),
      new FontBasePlugin(editor),
      new StyleSettingPlugin(editor),
      new FontSettingPlugin(editor),
      new ImagePlugin(editor, false),
      new LineHeightCustomPlugin(editor),
      new ClearFormatPlugin(editor),
      // new ToolBarTriggerPlugin(editor, () => {
      //   return <ToolBarTrigger />;
      // }),
    );
  }, [editor]);

  useImperativeHandle(ref, () => ({
    resetEditorContent,
  }));

  const resetEditorContent = () => {
    editor.raw.insertNode(getNewParagraphNode(''), { at: [0] });
    editor.raw.delete({
      at: {
        anchor: Editor.start(editor.raw, []),
        focus: Editor.end(editor.raw, []),
      },
      unit: 'block',
    });
    editor.raw.insertNode(getNewParagraphNode(''));
    editor.raw.deleteBackward('block');
  };

  useEffect(() => {
    const updateText = debounce(() => {
      setEditorContent(getEditorContent(editor));
    }, 100);

    editor.event.on(EDITOR_EVENT.CONTENT_CHANGE, updateText);
    return () => {
      editor.event.off(EDITOR_EVENT.CONTENT_CHANGE, updateText);
    };
  }, [editor.event]);

  useEffect(() => {
    if (!editorContent?.plain) {
      resetEditorContent();
    }
  }, [editorContent?.plain]);

  useEffect(() => {
    setEditor?.(editor.raw);
    setEditorKit?.(editor);
    setEditableRef?.(editableRef);
  }, [editor]);

  useEffect(() => {
    editor.raw.children = NullNode;
    ReactEditor.focus(editor.raw);
  }, [location]);

  return (
    <div ref={editableRef} className="editor-container relative" id="editor-container">
      {/* <SelectionBar readonly={editorReadonly} editor={editor} tooltipText={locale['toolsbar.insertContents']} excludeKey={[IMAGE_KEY, TABLE_BLOCK_CUSTOM_KEY]}>
        <SelectionBarPopup editorKit={editor} />
      </SelectionBar> */}
      <Editable editor={editor} placeholder={isAIWork ? '' : locale['slate.editor.placeholder']} readonly={editorReadonly} />
    </div>
  );
});

export default DocEditor;
