import { Input, TextAreaProps } from '@arco-design/web-react';
import { RefTextAreaType } from '@arco-design/web-react/es/Input';
import { useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';

type InputAreaRef = RefTextAreaType;
const InputTextArea = forwardRef<InputAreaRef, TextAreaProps>((props, ref) => {
  const textareaRef = useRef<any>(null);

  const [cursorIndex, setCursorIndex] = useState(0);

  useEffect(() => {
    textareaRef?.current?.setSelectionRange?.(cursorIndex, cursorIndex);
  }, [cursorIndex, textareaRef?.current]);

  useImperativeHandle(ref, () => {
    return {
      ...textareaRef.current,
    };
  });

  const handlePressEnter = (event: any) => {
    event.preventDefault();
    if (event.shiftKey) {
      const input = props.value || '';
      const textarea = event.target;

      const { selectionStart = 0, selectionEnd = 0 } = textarea;

      const newInput = `${input?.slice(0, selectionStart)}${`\n`}${input?.slice(selectionEnd)}`;
      props?.onChange?.(newInput, event);
      setCursorIndex(() => Number(selectionStart) + 1);
    } else {
      props?.onSubmit?.(event);
    }
    props?.onPressEnter?.(event);
  };

  return <Input.TextArea ref={textareaRef} {...props} onPressEnter={handlePressEnter} />;
});

export default InputTextArea;
