import { Editor, Transforms, ReactEditor, BaseRange } from '@hkgai/slate-plugin/dist/lib/delta';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { useModel, useStaticModel } from '@modern-js/runtime/model';
import { Message } from '@arco-design/web-react';
import dayjs from 'dayjs';
import { getClosestBlockPath } from '@hkgai/slate-plugin/dist/lib/utils';
import Generating, { GeneratingRef } from '../generating';
import GenerateMenuToolbar from '../generate-menu-toolbar';
import AIInputBox from '../ai-input-box';
import EscKeyListener from '../esc-key-listener';
import GenerationResult from '../generate-result';
import { updateToolbarPosition } from '../doc-editor/util';
import { getEditorContent, getNewParagraphNode, getReferencesContent, getContentByRange } from '@/views/writing/util';
import { continuationParagraph, expansionParagraph, rewriteParagraph, translateParagraph, summarizeParagraph, abortGenerate, fullArticle } from '@/api/event-source_api';
import { AIWorkStatus, WritingType, WritingTypeText } from '@/type/ui';
import { useWritingContext } from '@/context/writingContext';
import EditorStore, { WriteEssayParam } from '@/store/editorStore';
import { setGenerateParam } from '@/utils/generateParam';
import useLocale from '@/hooks/useLocale';
import { GlobalContext } from '@/routes/context';
import { ModuleName, PageNameConfig, WritingPageEvent } from '@/config/track.config';
import PromptHistoryStore from '@/store/promptHistoryStore';

export interface GenerateWrapperRef {
  handleWriteEssay: (param?: WriteEssayParam) => void;
}

interface GenerateWrapperProps {
  cancel?: () => void;
}

const GenerateWrapper = forwardRef<GenerateWrapperRef, GenerateWrapperProps>(({ cancel }, ref) => {
  const locale = useLocale();
  const { collectEvent } = useContext(GlobalContext);

  const generatingRef = useRef<GeneratingRef>(null);
  const { editor, editorKit, insertContent } = useWritingContext();
  const [
    { isAIWork, writingType, workStatus, writeEssayParam, visibleGInput, visibleGLoading, visibleGResult, generatingText },
    { changeWorkStatus, setWritingType, setGeneratingText, resetGeneratingText, setIsAIWork, changeEditorSelection, setWriteEssayParam },
  ] = useModel(EditorStore);
  // 初始化prmopt history
  const [_, { initialize }] = useModel(PromptHistoryStore);
  useEffect(() => {
    initialize();
  }, []);

  // https://modernjs.dev/guides/topic-detail/model/use-model.html#%E4%BD%9C%E4%B8%BA%E9%9D%99%E6%80%81%E7%8A%B6%E6%80%81%E4%BD%BF%E7%94%A8
  // 暂时解决同步函数里修改store没法马上获取到最新的值
  const [editorStoreState] = useStaticModel(EditorStore);
  const pageName = PageNameConfig.WRITING_PAGE;

  useImperativeHandle(ref, () => ({
    handleWriteEssay,
  }));

  //  开始写作  ai input
  const handleWriteEssay = async (param?: WriteEssayParam) => {
    changeWorkStatus?.(AIWorkStatus.WORK_PENDING);
    resetGeneratingText();

    const { content, style, lang, wordNum, references, fileText, user_ref, sence, enable_network_search } = param || writeEssayParam;
    let brainstorm = '';

    // 获取引用的内容
    if (references!.length > 0) {
      brainstorm = await getReferencesContent(references!);
    }

    // 增加file文件内容
    if (fileText!.length > 0) {
      brainstorm += fileText!.join(' ');
    }

    let tmpGText = '';

    const baseParams = {
      event: WritingPageEvent.CLICK_AI_WRITING,
      params: {
        pageName,
        moduleName: ModuleName.GENERATE_MENU_TOOLBAR,
        action: WritingTypeText[WritingType.AIWriting],
        writeStyle: style || '',
        writeLang: lang || '',
        writeWordNum: `${wordNum}`,
        writeBrainstorm: brainstorm,
      },
    };
    const startTime = dayjs().unix();
    const plaintext = getEditorContent(editorKit!).plain;

    let insertRange = Editor.range(editor!, editorStoreState.editorSelection || editor?.selection || []);

    fullArticle(
      content!,
      lang!,
      wordNum!,
      brainstorm,
      (text: string, status: AIWorkStatus | undefined) => {
        tmpGText += text;
        if (status === AIWorkStatus.CHATError) {
          // 触发最后一次markdown渲染
          insertRange = insertContent!('', insertRange, true);
          changeEditorSelection(insertRange);

          handleError();
          collectEvent?.([
            {
              ...baseParams,
              params: {
                ...baseParams.params,
                tokens: `${tmpGText.length}`,
                duration: `${dayjs().unix() - startTime}`,
                status: 'fail',
              },
            },
          ]);
          return;
        }

        if (status === AIWorkStatus.COMPLETED) {
          // 触发最后一次markdown渲染
          insertRange = insertContent!('', insertRange, true);
          changeEditorSelection(insertRange);

          setGenerateParam({ result: tmpGText });
          changeWorkStatus?.(AIWorkStatus.COMPLETED);
          collectEvent?.([
            {
              ...baseParams,
              params: {
                ...baseParams.params,
                tokens: `${tmpGText.length}`,
                duration: `${dayjs().unix() - startTime}`,
                status: 'success',
              },
            },
          ]);
          return;
        }

        changeWorkStatus?.(AIWorkStatus.WORKING);
        setGeneratingText?.(text);
        const needInsertText = text;

        insertRange = insertContent!(needInsertText, insertRange);
        // todo: 暂时还是更新store里的selection, 方便generate-result这个组件里用
        changeEditorSelection(insertRange);
      },
      plaintext,
      user_ref,
      sence,
      enable_network_search,
    );
  };

  // 终端生成时候的回调处理
  const handleCancel = () => {
    ReactEditor.focus(editor!);
    if (editorStoreState.editorSelection) {
      Transforms.select(editor!, editorStoreState.editorSelection);
    }
    abortGenerate();

    if (generatingText && generatingText.length > 0) {
      changeWorkStatus?.(AIWorkStatus.COMPLETED);
    } else {
      changeWorkStatus?.(AIWorkStatus.NOT_WORKING);
      setIsAIWork(false);
      setWritingType(WritingType.UNKNOWN);
    }
  };

  //   写作报错
  const handleError = () => {
    changeWorkStatus?.(AIWorkStatus.NOT_WORKING);
    setIsAIWork(false);
    setWritingType(WritingType.UNKNOWN);
    Message.warning(locale['handle.serve.error.message']);
  };

  //   扩写/改写/翻译
  const generateByToolBar = async (
    content: string,
    w: WritingType,
    l?: string,
    p?: string,
    startIdx?: number,
    endIdx?: number,
    enableNetworkSearch?: boolean,
    preContent?: string,
  ) => {
    let tmpGText = ''; // 当前会话生成字数
    const eventName = w === WritingType.EXPANSION ? WritingPageEvent.CLICK_EXPAND : WritingPageEvent.CLICK_REWRITE;
    const baseParams = {
      event: l ? WritingPageEvent.CLICK_TRANSLATE : eventName,
      params: {
        pageName,
        moduleName: ModuleName.GENERATE_MENU_TOOLBAR,
        action: WritingTypeText[w || WritingType.UNKNOWN],
      },
    };
    const startTime = dayjs().unix();
    const generateCallBack = (text: string, status: AIWorkStatus | undefined) => {
      if (status === AIWorkStatus.CHATError) {
        handleError();
        collectEvent?.([
          {
            ...baseParams,
            params: {
              ...baseParams.params,
              tokens: `${tmpGText.length}`,
              duration: `${dayjs().unix() - startTime}`,
              status: 'fail',
            },
          },
        ]);
        return;
      }

      if (status === AIWorkStatus.COMPLETED) {
        setGenerateParam({ result: tmpGText });
        changeWorkStatus?.(AIWorkStatus.COMPLETED);
        collectEvent?.([
          {
            ...baseParams,
            params: {
              ...baseParams.params,
              tokens: `${tmpGText.length}`,
              duration: `${dayjs().unix() - startTime}`,
              status: 'success',
            },
          },
        ]);
        return;
      }
      setGeneratingText?.(text);
      tmpGText += text;
      // ReactEditor.focus(editor!);
      // editorStoreState.editorSelection && Transforms.select(editor!, editorStoreState.editorSelection);
    };

    changeWorkStatus?.(AIWorkStatus.WORKING);
    resetGeneratingText?.();
    const plaintext = getEditorContent(editorKit!).plain;

    let brainstorm = '';
    if ([WritingType.EXPANSION, WritingType.REWRITE, WritingType.SUMMARIZE, WritingType.TRANSLATE].includes(w)) {
      // 获取引用的内容
      const { references = [], fileText = [] } = writeEssayParam;
      if (references.length) {
        brainstorm = await getReferencesContent(references);
      }

      // 增加file文件内容
      if (fileText.length) {
        brainstorm += fileText.join(' ');
      }
    }
    // 扩写
    if (w === WritingType.EXPANSION) {
      expansionParagraph(content, generateCallBack, plaintext, p, brainstorm, startIdx, endIdx, preContent);
    }
    // 改写
    if (w === WritingType.REWRITE) {
      rewriteParagraph(content, generateCallBack, plaintext, p, brainstorm, startIdx, endIdx, enableNetworkSearch, preContent);
    }
    // 翻译
    if (w === WritingType.TRANSLATE) {
      translateParagraph(content, l || '', generateCallBack, plaintext, brainstorm, p, startIdx, endIdx, preContent);
    }
    // 总结
    if (w === WritingType.SUMMARIZE) {
      summarizeParagraph(content, generateCallBack, plaintext, p, brainstorm, startIdx, endIdx, preContent);
    }
  };

  //   续写
  const generateByContinuation = async (
    content: string,
    w: WritingType,
    p?: string,
    startIdx?: number,
    endIdx?: number,
    newSelection?: BaseRange,
    enableNetworkSearch?: boolean,
    preContent?: string,
    isRegenerating = false,
  ) => {
    resetGeneratingText();
    // 进入等待调取接口等待，同时开始循环计算top的值
    changeWorkStatus?.(AIWorkStatus.WORK_PENDING);
    let tmpGText = '';
    const startTime = dayjs().unix();
    const plaintext = getEditorContent(editorKit!).plain;
    // 获取引用的内容
    let brainstorm = '';
    const { references = [], fileText = [] } = writeEssayParam;
    if (references.length) {
      brainstorm = await getReferencesContent(references);
    }

    // 增加file文件内容
    if (fileText.length) {
      brainstorm += fileText.join(' ');
    }

    // 续写另起一行
    const insertStart = Editor.end(editor!, newSelection || editor?.selection || []);
    let insertRange = Editor.range(editor!, insertStart);
    const emptyParagraphInsertedRange = insertContent!([getNewParagraphNode('')], insertRange);
    insertRange = emptyParagraphInsertedRange;

    continuationParagraph(
      content,
      (text: string, status) => {
        const baseParams = {
          event: WritingPageEvent.CLICK_CONTINUE_WRITING,
          params: {
            pageName,
            moduleName: ModuleName.GENERATE_MENU_TOOLBAR,
            action: WritingTypeText[w || WritingType.UNKNOWN],
          },
        };
        if (status === AIWorkStatus.CHATError) {
          // 触发最后一次markdown渲染
          insertRange = insertContent!('', insertRange, true);
          changeEditorSelection(insertRange);

          handleError();
          collectEvent?.([
            {
              ...baseParams,
              params: {
                ...baseParams.params,
                tokens: `${tmpGText.length}`,
                duration: `${dayjs().unix() - startTime}`,
                status: 'fail',
              },
            },
          ]);
          return;
        }
        if (status === AIWorkStatus.COMPLETED) {
          // 触发最后一次markdown渲染
          insertRange = insertContent!('', insertRange, true);
          changeEditorSelection(insertRange);

          collectEvent?.([
            {
              ...baseParams,
              params: {
                ...baseParams.params,
                tokens: `${tmpGText.length}`,
                duration: `${dayjs().unix() - startTime}`,
                status: 'success',
              },
            },
          ]);
          setGenerateParam({ result: tmpGText });
          changeWorkStatus?.(AIWorkStatus.COMPLETED);
          console.log('COMPLETED');
          return;
        }

        changeWorkStatus?.(AIWorkStatus.WORKING);
        setGeneratingText?.(text);
        tmpGText += text;
        // 插入生成的内容
        insertRange = insertContent!(text, insertRange);
        changeEditorSelection(insertRange);
      },
      plaintext,
      p,
      brainstorm,
      startIdx,
      endIdx,
      enableNetworkSearch,
      preContent,
    );
  };

  const handleMenuToolbar = (w: WritingType, l?: string, p?: string, network?: boolean) => {
    const [startPoint] = Editor.edges(editor!, editor!.selection!);
    //  获取选中和选中前文的内容
    const selectedContent = getContentByRange(editorKit!, editor!.selection!).plain || '';
    const previousContent = getContentByRange(editorKit!, { anchor: { path: [0, 0], offset: 0 }, focus: startPoint }).plain || '';
    //  计算文本中换行的个数(直接length的话，换行不计入长度)
    // const selectedCount = selectedContent.match(/\n/g)?.length || 0;
    // const previousCount = previousContent.match(/\n/g)?.length || 0;
    //  计算选区，在全文中的位置索引
    const startIdx = previousContent.length;
    const endIdx = startIdx + selectedContent.length - 1;

    setWriteEssayParam({ content: p || '', lang: l || '', selectedContent, selectedContentStartIdx: startIdx, selectedContentEndIdx: endIdx, selectedPreContent: previousContent });
    setIsAIWork?.(true);

    //  续写
    if (w === WritingType.CONTINUATION) {
      generateByContinuation(selectedContent, w, p, startIdx, endIdx, undefined, network, previousContent);
    } else {
      // 弹层的位置
      updateToolbarPosition(editor!, editorStoreState.editorSelection || editor?.selection || []);
      // bar上处理续写以外的 其他action翻译
      generateByToolBar(selectedContent, w, l, p, startIdx, endIdx, network, previousContent);
    }
  };

  useEffect(() => {
    if (writingType === WritingType.AIWriting && workStatus === AIWorkStatus.NOT_WORKING) {
      if (!editor) {
        return;
      }
      /** 打开AI writing输入框弹窗的逻辑
       * 1. 先定位光标位置:
       * 获取光标在文章内的位置
       * 如果没有focus或者光标在编辑器外部，光标定位在文章末尾
       * 所在段落有内容，则在光标所在段落后面插入新段落，并将光标定位到新段落
       * 2. 显示AI writing输入框:
       * 在光标位置下方弹出输入框
       */
      // editor && ReactEditor.focus(editor);
      let currentCursorLocation = editor.selection ? Editor.end(editor, editor.selection) : Editor.end(editor, []);
      // 当前段落
      const currentBlockPath = getClosestBlockPath(editor, currentCursorLocation);
      console.debug(currentBlockPath);
      // 当前段落内容
      const currentContent = currentBlockPath ? Editor.string(editor, currentBlockPath).trim() : '';
      // 当前段落有内容，在当前段落下面新增一个段落，并定位光标
      if (currentContent) {
        const insertedRange = insertContent!('\n', currentBlockPath!);
        currentCursorLocation = Editor.end(editor, insertedRange);
      }

      changeEditorSelection(Editor.range(editor, currentCursorLocation));
      // 打开弹层
      updateToolbarPosition(editor, currentCursorLocation);
    }
  }, [writingType, workStatus, editor]);

  return (
    <EscKeyListener onEscKeyDown={handleCancel}>
      <>
        {isAIWork ? (
          <div
            id="generate-wrap"
            className="generate-wrap"
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {visibleGInput && (
              <AIInputBox
                closeClick={() => {
                  setIsAIWork(false);
                  setWritingType(WritingType.UNKNOWN);
                  changeWorkStatus?.(AIWorkStatus.NOT_WORKING);
                }}
                sendStart={() => {
                  handleWriteEssay();
                }}
              />
            )}
            {visibleGLoading && (
              <Generating
                ref={generatingRef}
                cancel={() => {
                  handleCancel();
                }}
              />
            )}

            {visibleGResult && (
              // 重写生成
              <GenerationResult
                reGenerate={newSelection => {
                  // if (!writeEssayParam || !tmpContent || tmpContent === '') {
                  //   return;
                  // }

                  //  AI写作
                  if (writingType === WritingType.AIWriting) {
                    handleWriteEssay();
                  }

                  // 扩写 、 改写 、 翻译
                  if (
                    writingType === WritingType.EXPANSION ||
                    writingType === WritingType.REWRITE ||
                    writingType === WritingType.SUMMARIZE ||
                    writingType === WritingType.TRANSLATE
                  ) {
                    generateByToolBar(
                      writeEssayParam.selectedContent!,
                      writingType,
                      writeEssayParam.lang,
                      writeEssayParam.content,
                      writeEssayParam.selectedContentStartIdx,
                      writeEssayParam.selectedContentEndIdx,
                      writeEssayParam.enable_network_search,
                      writeEssayParam.selectedPreContent,
                    );
                  }

                  // 续写
                  if (writingType === WritingType.CONTINUATION) {
                    generateByContinuation(
                      writeEssayParam.selectedContent!,
                      writingType,
                      writeEssayParam.content,
                      writeEssayParam.selectedContentStartIdx,
                      writeEssayParam.selectedContentEndIdx,
                      newSelection,
                      writeEssayParam.enable_network_search,
                      writeEssayParam.selectedPreContent,
                      true,
                    );
                  }
                }}
                onCancel={handleCancel}
              />
            )}
          </div>
        ) : (
          // 翻译 /续写
          <GenerateMenuToolbar
            menuToolbarClick={(w, l, p, network) => {
              handleMenuToolbar(w, l, p, network);
            }}
          />
        )}
      </>
    </EscKeyListener>
  );
});

export default GenerateWrapper;
