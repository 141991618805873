import './index.scss';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button } from '@arco-design/web-react';
import useLocale from '@hooks/useLocale';
import { useModel } from '@modern-js/runtime/model';
import { IconMagicPencil } from '@arco-iconbox/react-hkgai-fe-icon';
import { IconPauseCircle } from '@arco-iconbox/react-hkgai-government-icon';
import EscKeyListener from '../esc-key-listener';
import { AIWorkStatus } from '@/type/ui';
import { abortGenerate } from '@/api/event-source_api';
import EditorStore from '@/store/editorStore';

export interface GeneratingRef {
  rejectResponse: () => void;
}

interface GeneratingProps {
  cancel?: () => void;
}

const Generating = forwardRef<GeneratingRef, GeneratingProps>(({ cancel }, ref) => {
  // const { editor } = useWritingContext();
  const locale = useLocale();
  const [state] = useModel(EditorStore);
  const [shake, setShake] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    rejectResponse,
  }));

  const loadingText = useMemo(() => {
    let text = locale['generatingBox.loading.thinking'];
    switch (state.workStatus) {
      case AIWorkStatus.WORK_PENDING:
        text = locale['generatingBox.loading.thinking'];
        break;
      case AIWorkStatus.WORKING:
        text = locale['generatingBox.loading.writing'];
        break;
      default:
    }
    return text;
  }, [state.workStatus, locale]);

  const rejectResponse = () => {
    setShake(() => true);
    setTimeout(() => {
      setShake(() => false);
    }, 600);
  };

  const pauseGenerate = () => {
    // 取消生成
    abortGenerate();
    cancel?.();
  };

  //   const handleEsc = (event: any) => {
  //     if (event.key === 'Escape' || event.keyCode === 27) {
  //       pauseGenerate();
  //     }
  //   };

  //   useEffect(() => {
  //     document.addEventListener('keydown', handleEsc);
  //     return () => {
  //       document.removeEventListener('keydown', handleEsc);
  //     };
  //   }, []);

  return (
    <EscKeyListener onEscKeyDown={pauseGenerate}>
      <div
        className={classNames('generating-wrapper', { 'shake-trigger': shake })}
        // onClick={() => {
        //   rejectResponse();
        // }}
        onMouseDown={e => {
          // prevent toolbar from taking focus away from editor
          e.preventDefault();
        }}
      >
        <div className="content">
          <div className="relative flex items-center rounded-lg bg-white">
            <IconMagicPencil style={{ width: '24px', height: '24px', color: '#1B2559' }} />
            <div className="ml-2 animate-fade text-_body-3 font-medium text-_2 animate-duration-500" style={{ color: '#1B2559' }}>
              {loadingText} ...
            </div>
            {/* <TextLoading /> */}
          </div>

          <Button
            type="text"
            className="ml-auto flex items-center gap-x-1 text-_body-1 font-medium text-_3 transition-colors hover:text-_2"
            // style="pointer-events: auto;"
            onClick={() => {
              pauseGenerate();
            }}
          >
            {/* <IconRecordStop style={{ width: '32px', height: '32px', color: '#1B2559' }} /> */}
            <IconPauseCircle style={{ width: '32px', height: '32px', color: '#1B2559' }} />
          </Button>
        </div>
      </div>
    </EscKeyListener>
  );
});

export default Generating;
