import { Trigger, Button, Tooltip } from '@arco-design/web-react';
import { IconDown, IconFontColors } from '@arco-design/web-react/icon';
import { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { useSessionStorageState } from 'ahooks';
import { STYLE_SETTING_KEY } from '@hkgai/slate-plugin/dist/lib/plugins';
import styles from './index.module.scss';
import { MenuItemOptions } from '@/type/toolsbar';
import useLocale from '@/hooks/useLocale';

interface ColorConfig {
  [key: string]: string;
}

const initColorConfig = {
  background: '#FDFA94',
  color: '#222',
};

const ColorMenu: FC<{
  menuKey: string;
  children: ReactNode;
  options: MenuItemOptions;
  onSelect: (key: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
}> = ({ menuKey, options, children, onSelect, disabled = false }) => {
  const locale = useLocale();
  const [colorConfig, setColorConfig] = useSessionStorageState<ColorConfig>('colorConfig', {
    defaultValue: initColorConfig,
  });

  const { colorMap, backgroundMap, reset } = options;
  const [visible, setVisible] = useState(false);
  const popupComp = () => (
    <div className={styles.colorSetting}>
      <div className={styles.title}>{locale['toolsbar.textColour']}</div>
      <div className={styles.colorWrap}>
        {colorMap?.map(item => (
          <div
            key={item.key}
            className={classNames(styles.colorItem, { [styles.colorItemActive]: colorConfig?.color === item.value })}
            style={{ color: item.value as string }}
            onMouseDown={e => e.preventDefault()}
            onClick={e => {
              const [, extraKey] = item.key.split('.');
              const [colorKey, colorValue] = extraKey.split(':');
              setColorConfig(pre => ({
                ...pre,
                [colorKey]: colorValue,
              }));
              onSelect(item.key, e);
            }}
          >
            <IconFontColors />
          </div>
        ))}
      </div>
      <div className={styles.title}>{locale['toolsbar.backgroundColour']}</div>
      <div className={styles.colorWrap}>
        {backgroundMap?.map((item, index) => (
          <div
            key={item.key}
            className={classNames(styles.colorItem, { [styles.colorItemFirst]: index === 0, [styles.colorItemActive]: colorConfig?.background === item.value })}
            style={{ background: item.value as string }}
            onMouseDown={e => e.preventDefault()}
            onClick={e => {
              const [, extraKey] = item.key.split('.');
              const [colorKey, colorValue] = extraKey.split(':');
              setColorConfig(pre => ({
                ...pre,
                [colorKey]: colorValue,
              }));
              onSelect(item.key, e);
            }}
          />
        ))}
      </div>
      <Button
        className="reset"
        key={reset?.key}
        long
        onMouseDown={e => e.preventDefault()}
        onClick={e => {
          setColorConfig(initColorConfig);
          onSelect(reset!.key, e as any);
        }}
      >
        {locale['toolsbar.reset']}
      </Button>
    </div>
  );
  return (
    <div className={classNames(styles.colorMenuWrap, { [styles.colorMenuWrapActive]: visible, [styles.colorMenuWrapDisabled]: disabled })}>
      <Tooltip content={locale[menuKey]} disabled={!locale[menuKey] || disabled}>
        <div
          className={styles.colorMenuIcon}
          style={{ background: colorConfig?.background, color: colorConfig?.color }}
          onMouseDown={e => e.preventDefault()}
          onClick={e => {
            if (disabled) {
              return;
            }
            const str = Object.entries(colorConfig || {})
              .map(([key, value]) => `${key}:${value}`)
              .join(',');
            onSelect(`${STYLE_SETTING_KEY}.${str}`, e);
          }}
        >
          {children}
        </div>
      </Tooltip>
      <Trigger
        popup={popupComp}
        trigger="hover"
        popupAlign={{
          bottom: 8,
        }}
        position="bottom"
        popupVisible={visible}
        onVisibleChange={v => setVisible(v)}
        disabled={disabled}
      >
        <div className={classNames(styles.triggrtMenuContainer)}>
          <IconDown className={styles.iconDown} />
        </div>
      </Trigger>
    </div>
  );
};
export default ColorMenu;
