import { FC, useEffect, useRef, useState, useMemo, useContext } from 'react';
import { Modal, Button, Tooltip } from '@arco-design/web-react';
import { IconFileText, IconQuestionCircle, IconUserSound } from '@arco-iconbox/react-hkgai-government-icon';
import { useModel } from '@modern-js/runtime/model';
import { useClickAway } from 'ahooks';
import AiInputToolbar, { ParamKeys } from '../ai-input-toolbar';
import InputTextArea from '../input-textarea';
import EditorStore, { WriteEssayParam } from '@/store/editorStore';
import useLocale from '@/hooks/useLocale';
import Attachment from '@/components/attachment';
import { Resource } from '@/components/upload-modal';
import { WorkFlowScene } from '@/type/ui';
import { AIWritingPromptHistory } from '@/components/promptHistory';
import PromptHistoryStore from '@/store/promptHistoryStore';
import { GlobalContext } from '@/routes/context';
import { ModuleName, PageNameConfig, WritingPageEvent } from '@/config/track.config';
import { MAX_USER_PROMPT_LEN, MAX_USER_REFERENCE_LEN } from '@/config/constant';

interface AiInputBoxProp {
  // mode?: string; // 值: body | pop
  closeClick?: () => void;
  sendStart?: (params?: WriteEssayParam) => void;
}

const Index: FC<AiInputBoxProp> = ({ closeClick, sendStart }) => {
  const locale = useLocale();
  const textareaRef = useRef<any>(null);

  const [files, setFiles] = useState<Resource[]>([]);

  const [{ writeEssayParam, visibleGInput, writingParamEntered }, { setWriteEssayParam }] = useModel(EditorStore);

  const { collectEvent } = useContext(GlobalContext);

  const [, { addAIWritingPromptHistory }] = useModel(PromptHistoryStore);

  const startWriteEnabled = useMemo(() => {
    return Boolean(writingParamEntered) && writeEssayParam.content;
  }, [writeEssayParam, files]);

  const startWrite = () => {
    if (!startWriteEnabled) {
      return;
    }
    console.log(JSON.stringify(writeEssayParam));
    sendStart?.();
    // 存一下prompt history
    addAIWritingPromptHistory(writeEssayParam.content);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timer;
    if (textareaRef.current && visibleGInput) {
      timeoutId = setTimeout(() => {
        textareaRef.current?.focus?.();
      }, 400);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [visibleGInput]);

  const onChangeSelect = (value: string, key: ParamKeys) => {
    setWriteEssayParam({ ...writeEssayParam, ...{ [key]: key === ParamKeys.WORD_NUM ? Number(value) : value } });
  };

  useClickAway(
    event => {
      const { target } = event;
      const aiWritingButton = document.getElementById('ai_writing');
      const isClickingAIWritingButton = aiWritingButton?.contains(target as Node);
      const isAIWritingInputOpen = visibleGInput;
      const keepOpening = isAIWritingInputOpen && writingParamEntered;

      // 输入框打开了，有内容，阻止直接关闭
      if (keepOpening) {
        Modal.confirm({
          style: { transform: 'none', animation: 'none' },
          autoFocus: false,
          title: locale['confirm.modal.quit.title'],
          wrapClassName: 'ai-input-modal-wrap',
          content: <div className="w-full text-center">{locale['confirm.modal.quit.content']}</div>,

          onOk: () => {
            closeClick?.();
          },
          okButtonProps: { style: { backgroundColor: '#F53F3F' } },
          getPopupContainer: () => document.getElementsByClassName('ai-input-box')?.[0],
        });
      } else if (!isClickingAIWritingButton) {
        closeClick?.();
      }
    },
    [
      // 输入框
      () => document.getElementsByClassName('ai-input-box')?.[0],
    ],
  );

  useEffect(() => {
    if (files) {
      setWriteEssayParam({ fileText: files.map(file => file.value || '') });
    }
  }, [files]);

  const writingSenceNode = useMemo(() => {
    const { sence } = writeEssayParam;
    let inner = null;
    if (sence === WorkFlowScene.SPEECH) {
      inner = (
        <>
          <IconUserSound />
          演讲稿
        </>
      );
    }
    if (sence === WorkFlowScene.WORK_REPORT) {
      inner = (
        <>
          <IconUserSound />
          演讲稿
        </>
      );
    }
    if (sence === WorkFlowScene.POLICY_ADDRESS) {
      inner = (
        <>
          <IconFileText />
          工作总结
        </>
      );
    }

    return inner ? <div className="sence-tag">{inner}</div> : null;
  }, [writeEssayParam.sence]);

  return (
    <>
      <div className="ai-input-box" onClick={e => e.stopPropagation()}>
        <div className="input-item">
          <div className="flex justify-between items-center">
            <div className="label">
              <span>
                {locale['ai.writing.input.label.user.instruction']} <span className="text-red-500">*</span>{' '}
              </span>
              {writingSenceNode}
              <Tooltip content={locale['ai.writing.input.label.user.instruction.tooltip']}>
                <IconQuestionCircle className="cursor-pointer" />
              </Tooltip>
            </div>
            <div style={{ color: 'rgba(204, 204, 204, 1)', fontSize: '12px', marginBottom: '12px' }}>
              <span style={{ color: !writeEssayParam.content?.length ? 'rgba(204, 204, 204, 1)' : ' rgba(34, 34, 34, 1)' }}>{writeEssayParam.content?.length}</span> /1000
            </div>
          </div>
          <div className="w-fit h-fit relative">
            <InputTextArea
              ref={textareaRef}
              autoSize
              className="input-area suffix"
              placeholder={locale['inputBox.textarea.placeholder']}
              onChange={value => {
                const val = value.length <= MAX_USER_PROMPT_LEN ? value : value.slice(0, MAX_USER_PROMPT_LEN);
                setWriteEssayParam({ content: val });
              }}
              value={writeEssayParam.content}
              onSubmit={startWrite}
            />
            <div className="absolute right-2.5 top-1/2" style={{ transform: 'translateY(-50%)' }}>
              <AIWritingPromptHistory
                onChange={val => {
                  setWriteEssayParam({ content: val });
                  collectEvent?.([
                    {
                      event: WritingPageEvent.CLICK_AI_WRITING_PROMPT_HISTORY,
                      params: {
                        moduleName: ModuleName.AI_WRITING_INPUT,
                        pageName: PageNameConfig.WRITING_PAGE,
                      },
                    },
                  ]);
                }}
              />
            </div>
          </div>
        </div>
        <div className="input-item">
          <div className="flex justify-between  items-center">
            <div className="label">{locale['ai.writing.input.label.reference']}</div>
            <div style={{ color: 'rgba(204, 204, 204, 1)', fontSize: '12px', marginBottom: '12px' }}>
              <span>{locale['writing.wordCount']}：</span>
              <span style={{ color: !writeEssayParam.user_ref?.length ? 'rgba(204, 204, 204, 1)' : ' rgba(34, 34, 34, 1)' }}> {writeEssayParam.user_ref?.length}</span>
            </div>
          </div>
          <InputTextArea
            autoSize
            className="input-area"
            placeholder={locale['ai.writing.input.placeholder.reference']}
            onChange={value => {
              const val = value.length <= MAX_USER_REFERENCE_LEN ? value : value.slice(0, MAX_USER_REFERENCE_LEN);
              setWriteEssayParam({ user_ref: val });
            }}
            value={writeEssayParam.user_ref}
            onSubmit={startWrite}
          />
        </div>
        <div className="input-item flex items-end justify-between">
          <div className="operate-box">
            {/* <div className="mr-[10px] flex items-center gap-1">
              <Switch checked={writeEssayParam.enable_network_search} onChange={checked => setWriteEssayParam({ enable_network_search: checked })} />
              <span>{locale['ai.writing.input.enable_network_search']}</span>
            </div> */}
            <AiInputToolbar
              writeEssayParam={writeEssayParam}
              onChange={onChangeSelect}
              onAttachmentChange={(files = [], links = []) => {
                setFiles(files);
                setWriteEssayParam({ references: links.map(e => e.name || '') });
                collectEvent?.([
                  {
                    event: WritingPageEvent.UPLOAD_FILE,
                    params: {
                      moduleName: ModuleName.AI_WRITING_INPUT,
                      num: files.length.toString(),
                    },
                  },
                  {
                    event: WritingPageEvent.ADD_LINK,
                    params: {
                      moduleName: ModuleName.AI_WRITING_INPUT,
                      num: links.length.toString(),
                    },
                  },
                ]);
              }}
            />
          </div>
          <Button type="primary" size="large" className="input-box-send-button" onClick={() => startWrite()} disabled={!startWriteEnabled}>
            {locale['inputBox.send.button.tooltip']}
          </Button>
        </div>
        <div className="input-item flex flex-wrap items-center gap-4" style={{ marginBottom: 0 }}>
          {files.map(file => (
            <Attachment
              key={file.uuid}
              title={file.name}
              type="file"
              onClose={() => {
                setFiles(files => files.filter(e => e.uuid !== file.uuid));
              }}
            />
          ))}
          {writeEssayParam.references?.map(ref => (
            <Attachment
              key={ref}
              title={ref}
              type="link"
              onClose={() => {
                setWriteEssayParam({
                  references: writeEssayParam.references?.filter(reference => reference !== ref),
                });
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Index;
