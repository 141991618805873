import './index.scss';

import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useModel } from '@modern-js/runtime/model';
import classNames from 'classnames';
import { useLocation } from '@modern-js/runtime/router';
import dayjs from 'dayjs';
import { Modal } from '@arco-design/web-react';
import { ToolBar } from '@hkgai/slate-plugin/dist/lib/plugins/tool-bar';
import GenerateWrapper, { GenerateWrapperRef } from './components/generate-wrapper';
import DocEditor, { DocEditorRef } from './components/doc-editor';
import { countWords, saveFileFromBuffer } from './util';
import BtnAskAI from './components/btn-ask-ai';
import Footer from '@/components/footer';
import useUrlParams from '@/hooks/useUrlParams';
import EditorStore from '@/store/editorStore';
import { AIWorkStatus, WritingType } from '@/type/ui';
import useLocale from '@/hooks/useLocale';
import { RequireAuth } from '@/routes/auth';
import { GlobalContext } from '@/routes/context';
import { fetchDownloadFile } from '@/api/copilot_api';
import { useVisibilityChange } from '@/hooks/useVisibilityChange';
import { PageEvent, PageNameConfig } from '@/config/track.config';
import { SidebarType } from '@/type/copilot';
// import ProofreadingBar from '@/components/proofreading/bar';
import useControlAIWritingInput from '@/hooks/useControlAIWritingInput';
import { useArticleId } from '@/hooks/article';
import { useRoutingBlocker } from '@/hooks/usRoutingBlocker';
import AiWritingStore from '@/store/aiWritingStore';
import { useWritingContext } from '@/context/writingContext';

const Index = () => {
  const locale = useLocale();
  const { newChat } = useUrlParams();
  const documentVisible = useVisibilityChange();
  const timeRef = useRef(dayjs().unix());

  const { collectEvent, lang } = useContext(GlobalContext);

  const { editorKit } = useWritingContext();

  const location = useLocation();
  //   const titleInputRef = useRef<RefInputType>(null);
  const [sidebarType, setSidebarType] = useState<SidebarType>(SidebarType.UNKNOWN);

  const [{ editorContent, editorSize, visibleGInput, writingParamEntered, workStatus }, { clearEditorContent, setWritingType, setIsAIWork }] = useModel(EditorStore);

  const [{ aiWritingList }] = useModel(AiWritingStore);

  const articleId = useArticleId();

  const docEditorRef = useRef<DocEditorRef>(null);
  const generateWrapperRef = useRef<GenerateWrapperRef>(null);

  const pathname = location.state?.from?.pathname;
  const from = pathname || '/writing';
  const pageName = PageNameConfig.WRITING_PAGE;
  const fromLink = pathname ? from : '';

  useEffect(() => {
    if (documentVisible) {
      timeRef.current = dayjs().unix();
      collectEvent?.([
        {
          event: PageEvent.PAGE_SHOW,
          params: {
            fromLink,
            pageName,
          },
        },
      ]);
    } else {
      onUnMounted();
    }
  }, [documentVisible]);

  const onUnMounted = () => {
    collectEvent?.([
      {
        event: PageEvent.PAGE_HIDE,
        params: {
          fromLink,
          pageName,
          duration: `${dayjs().unix() - timeRef.current}`,
        },
      },
    ]);
  };

  useEffect(() => {
    clearEditorContent();
    setIsAIWork(false);
    setWritingType(WritingType.UNKNOWN);
    // if (titleInputRef?.current) {
    //   titleInputRef.current.focus();
    // }
  }, [newChat, location]);

  useEffect(() => {
    return () => {
      clearEditorContent();
    };
  }, []);

  const { open: openAIWriting, close: closeAIWriting, setWritingSence } = useControlAIWritingInput();

  const handleSidebarClick = (type: SidebarType) => {
    setSidebarType(oldType => (oldType === type ? SidebarType.UNKNOWN : type));
    // AI 写作
    if (type === SidebarType.COPILOT) {
      const isAIWritingInputOpen = visibleGInput;
      const keepOpening = isAIWritingInputOpen && writingParamEntered;
      if (keepOpening) {
        return;
      }

      // 打开行内 写作引导框体
      if (!isAIWritingInputOpen) {
        openAIWriting();
      } else {
        // 关闭写作框体
        closeAIWriting();
      }
      setWritingSence('');
      return;
    }
    closeAIWriting();
  };

  const handleDownloadFile = async () => {
    try {
      if (!editorContent?.plain?.length) {
        return;
      }
      const fileKey = `${dayjs().valueOf()}`;
      console.log('editorChildren', JSON.stringify(editorContent));
      const data = await fetchDownloadFile(fileKey, editorContent?.slateDom || '');
      saveFileFromBuffer(data, `HKCopilot_${fileKey}`);
    } catch (error) {}
  };

  const handleClearEditorContext = (fn: () => void) => {
    Modal.confirm({
      autoFocus: false,
      title: locale['writing.confirm.modal.title'],
      content: <div className="text-center">{locale['writing.confirm_modal.content']}</div>,
      okText: locale['writing.confirm_modal.button.confirm'],
      cancelText: locale['writing.confirm.modal.button.cancel'],
      okButtonProps: {
        status: 'danger',
      },
      onOk: () => {
        setIsAIWork(false);
        setWritingType(WritingType.UNKNOWN);
        fn();
      },
    });
  };

  useRoutingBlocker(({ nextLocation, currentLocation, historyAction }) => {
    if (nextLocation.key !== currentLocation.key && workStatus !== undefined) {
      return [AIWorkStatus.WORKING, AIWorkStatus.WORK_PENDING].includes(workStatus);
    }
    return false;
  }, locale.ad_writing_generating_notification);

  const articleTitle = useMemo(() => {
    return aiWritingList?.find(item => item.articleId === articleId)?.title;
  }, [aiWritingList, articleId]);

  return (
    <RequireAuth>
      <>
        {/** 粗暴的重新挂载一下ai-writing的页面 */}
        <div className="writing-wrap" key={articleId}>
          <div className="writing-main">
            <div className="relative h-[36px] overflow-hidden flex align-center justify-center border-b-[1px] border-0 border-solid border-[#e2e8f0]">
              <ToolBar editor={editorKit} articleTitle={articleTitle} articleId={articleId} lang={lang} clearCb={fn => handleClearEditorContext(fn)} className="pl-[50px]" />
              <div className="word-count">
                <div style={{ color: 'rgba(204, 204, 204, 1)' }} className="select-none">
                  {locale['writing.wordCount']}：
                </div>
                <div style={{ width: '44px', textAlign: 'left', color: !editorContent?.plain?.length ? 'rgba(204, 204, 204, 1)' : ' rgba(34, 34, 34, 1)' }}>
                  {countWords(editorContent?.plain || '')}
                </div>
              </div>
            </div>

            {/* <div className="writing-actions text-right">
              <div className={classNames('icon-button clear-btn', { 'icon-button-dis': !editorContent?.plain?.length })} onClick={handleClearEditorContext}>
                <IconClear />
                <div>{locale['writing.clear.button']}</div>
              </div>
              <div className={classNames('icon-button download-btn', { 'icon-button-dis': !editorContent?.plain?.length })} onClick={handleDownloadFile}>
                <IconToBottom />
                <div>{locale['writing.download.text']}</div>
              </div>
              <div className="word-count">
                  <div style={{ color: 'rgba(204, 204, 204, 1)' }} className="select-none">
                    {locale['writing.wordCount']}：
                  </div>
                  <div style={{ width: '44px', textAlign: 'left', color: !editorContent?.plain?.length ? 'rgba(204, 204, 204, 1)' : ' rgba(34, 34, 34, 1)' }}>
                    {countWords(editorContent?.plain || '')}
                  </div>
                </div>
            </div> */}

            <div className={classNames('writing-body')} id="writing-body">
              <div id="editor-wrap" className={classNames('editor-wrap', { 'small-size': editorSize === 'small' })}>
                <div className="sticky top-0 bg-white z-[1] pb-6">
                  <BtnAskAI btnAskAIClick={() => handleSidebarClick(SidebarType.COPILOT)} />
                </div>

                <DocEditor ref={docEditorRef} />
                <GenerateWrapper ref={generateWrapperRef} />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </>
    </RequireAuth>
  );
};

export default Index;
