export const expansionBuildinPrompts = [
  {
    label: 'ai.writing.expand.prompt.1',
    value: 'ai.writing.expand.prompt.value.1',
  },
  {
    label: 'ai.writing.expand.prompt.2',
    value: 'ai.writing.expand.prompt.value.2',
  },
  {
    label: 'ai.writing.expand.prompt.3',
    value: 'ai.writing.expand.prompt.value.3',
  },
];

export const rewriteBuildinPrompts = [
  {
    label: 'ai.writing.rewrite.prompt.1',
    value: 'ai.writing.rewrite.prompt.value.1',
  },
  {
    label: 'ai.writing.rewrite.prompt.2',
    value: 'ai.writing.rewrite.prompt.value.2',
  },
  // {
  //   label: 'ai.writing.rewrite.prompt.3',
  //   value: 'ai.writing.rewrite.prompt.value.3',
  // },
  {
    label: 'ai.writing.rewrite.prompt.4',
    value: 'ai.writing.rewrite.prompt.value.4',
  },
  // {
  //   label: 'ai.writing.rewrite.prompt.5',
  //   value: 'ai.writing.rewrite.prompt.value.5',
  // },
  {
    label: 'ai.writing.type.customize',
    value: '',
  },
];

// export const summaryBuildinPromps = [
//   {
//     label: '听起来更专业',
//     value: '听起来更专业',
//   },
// ];

export const contiueWritingBuildinPrompts = [
  {
    label: 'ai.writing.continue.prompt.1',
    value: 'ai.writing.continue.prompt.value.1',
  },
  {
    label: 'ai.writing.continue.prompt.2',
    value: 'ai.writing.continue.prompt.value.2',
  },
  {
    label: 'ai.writing.type.customize',
    value: '',
  },
];

export const summaryBuildinPrompts = [
  {
    label: 'ai.writing.summary.prompt.1',
    value: 'ai.writing.summary.prompt.1',
  },
  {
    label: 'ai.writing.summary.prompt.2',
    value: 'ai.writing.summary.prompt.2',
  },
  {
    label: 'ai.writing.summary.prompt.3',
    value: '',
  },
];
