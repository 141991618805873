import './index.scss';
import { IconExclamationCircle2 } from '@arco-iconbox/react-hkgai-government-icon';
import React from 'react';
import useLocale from '@/hooks/useLocale';

const FeedbackButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const locale = useLocale();
  return (
    <div className="btn_report cursor-pointer" onClick={onClick}>
      <IconExclamationCircle2 width="16px" height="16px" />
      {locale['ai.writing.generate.button.report']}
    </div>
  );
};

export default FeedbackButton;
