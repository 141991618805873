import { useCallback, useEffect } from 'react';

// 定义Props类型接口
interface EscKeyListenerProps {
  children: React.ReactNode;
  onEscKeyDown: () => void;
}

// 自定义Hook
const useEscKeyListener = (onEscKeyDown: () => void) => {
  // 使用useCallback来避免在每次渲染时创建新的函数
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.keyCode === 27) {
        onEscKeyDown();
      }
    },
    [onEscKeyDown],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};

// 函数组件
const EscKeyListener: React.FC<EscKeyListenerProps> = ({ children, onEscKeyDown }) => {
  useEscKeyListener(onEscKeyDown);

  return <>{children}</>;
};

export default EscKeyListener;
