import { FC, useMemo, useState } from 'react';

import classNames from 'classnames';
import { EditorKit } from '@hkgai/slate-plugin/dist/lib/core';
import { Tooltip } from '@arco-design/web-react';
import { useMemoizedFn } from 'ahooks';
import styles from './index.module.scss';
import { CustomEventOptions, DoActions, MenuItem, MenuItemOptions } from '@/type/toolsbar';
import useLocale from '@/hooks/useLocale';

const GroupMenu: FC<{
  options: MenuItemOptions;
  onSelect: (key: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  editorContentPlain?: string;
  editorKit?: EditorKit;
  disabled?: boolean;
}> = ({ options, onSelect, editorContentPlain, editorKit, disabled = false }) => {
  const locale = useLocale();
  const [visible, setVisible] = useState(false);

  const getGroupMenuItemDisabled = (item: MenuItem) => {
    if (item.key === CustomEventOptions.DOWNLOAD || item.key === CustomEventOptions.DELETE) {
      return !editorContentPlain;
    }
    if (item.key === DoActions.REDO) {
      return !editorKit?.track.canRedo();
    }
    if (item.key === DoActions.UNDO) {
      return !editorKit?.track.canUndo();
    }
    return false;
  };

  const comp = useMemoizedFn(() => {
    return (
      <>
        {options.commonConfig?.map(item => {
          return (
            <Tooltip content={locale[item.key]} disabled={!locale[item.key] || disabled} key={item.key}>
              <div
                className={classNames(styles.groupMenuItem, {
                  [styles.groupMenuItemActive]: visible,
                  [styles.groupMenuItemDisabled]: getGroupMenuItemDisabled(item) || disabled,
                })}
                style={{ color: item.iconColor }}
                onMouseDown={e => e.preventDefault()}
                onClick={e => {
                  if (getGroupMenuItemDisabled(item) || disabled) {
                    return;
                  }
                  onSelect(item.key, e);
                }}
              >
                {item.icon}
              </div>
            </Tooltip>
          );
        })}
      </>
    );
  });

  return <div className={classNames(styles.groupMenuContainer)}>{comp()}</div>;
};
export default GroupMenu;
