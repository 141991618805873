import { Select, Tooltip } from '@arco-design/web-react';
import { FC, useEffect, useState } from 'react';
import { IconCheck } from '@arco-design/web-react/icon';
import styles from './index.module.scss';
import { MenuItem, SelectedMarks } from '@/type/toolsbar';
import useLocale from '@/hooks/useLocale';

const InitSize = 16;

const FontMenu: FC<{
  menuKey: string;
  selectedMarks: SelectedMarks;
  fontSizeMap: MenuItem[];
  onSelect: (key: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
}> = ({ fontSizeMap, onSelect, menuKey, selectedMarks, disabled = false }) => {
  const locale = useLocale();
  const [value, setValue] = useState(InitSize);
  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(selectedMarks, menuKey)) {
      setValue(Number(selectedMarks[menuKey].fontSize.replace('px', '')));
    } else {
      setValue(InitSize);
    }
  }, [selectedMarks]);
  return (
    <Tooltip content={locale[menuKey]} disabled={!locale[menuKey] || disabled}>
      <Select
        onChange={value => {
          setValue(value);
        }}
        value={value}
        dropdownMenuClassName={styles.selectOptions}
        className={styles.select}
        trigger="hover"
        triggerProps={{
          autoAlignPopupWidth: false,
          autoAlignPopupMinWidth: true,
          position: 'bottom',
        }}
        disabled={disabled}
      >
        {fontSizeMap.map(option => (
          <Select.Option key={option.key} value={option.value || InitSize}>
            <div onClick={e => onSelect(option.key, e)}>
              <IconCheck className={styles.checkIcon} />
              {option.value}
            </div>
          </Select.Option>
        ))}
      </Select>
    </Tooltip>
  );
};
export default FontMenu;
