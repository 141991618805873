import { Button, Form, Grid, Input, Modal, Rate } from '@arco-design/web-react';
import { FC, useEffect, useMemo, useState } from 'react';
import { IconClose } from '@arco-design/web-react/icon';
import classNames from 'classnames';
import useLocale from '@/hooks/useLocale';

interface FeedbackCompProps {
  visiable: boolean;
  onCancel?: () => void;
  onOk?: (formValues?: any) => void;
  className?: string;
}

enum Step {
  UNKNOW,
  ONE,
  TWO,
  THREE,
  FOUR,
}

const FeedbackComp: FC<FeedbackCompProps> = ({ visiable, onCancel, onOk, className }) => {
  const [form] = Form.useForm();
  const locale = useLocale();
  const [current, setCurrent] = useState<Step>(Step.ONE);

  const rateOne: string = Form.useWatch('one', form);
  const rateTwo: string = Form.useWatch('two', form);
  const rateThree: string = Form.useWatch('three', form);
  const rateRemark: string = Form.useWatch('remark', form);

  const feedBackModalIsConfirm = useMemo(() => Boolean(rateOne || rateTwo || rateThree || rateRemark), [rateOne, rateTwo, rateThree, rateRemark]);

  const reportConfirm = (formValues: any) => {
    Modal.confirm({
      title: locale['privacy.modal.report.title'],
      // eslint-disable-next-line react/no-danger
      content: <div style={{ width: '400px' }} dangerouslySetInnerHTML={{ __html: locale['privacy.modal.report.content'] }}></div>,
      okText: locale['privacy.modal.report.submit'],
      cancelText: locale['privacy.modal.report.cancel'],
      // onCancel: () => {
      //    onCancel?.();
      // },
      onOk: async () => {
        try {
          onOk?.(formValues);
        } catch (error) {}
      },
    });
  };

  useEffect(() => {
    setCurrent(Step.ONE);
    form.resetFields();
  }, [visiable]);

  if (!visiable) {
    return null;
  }
  return (
    <div className={classNames('min-h-[58px] flex flex-col justify-center items-center relative px-[3px]', className)}>
      <Button
        shape="round"
        size="mini"
        className="flex justify-center items-center absolute right-[-4px] top-[-4px] z-[1]"
        type="text"
        icon={<IconClose className="text-[#4E5969] text-[16px]" />}
        onClick={() => {
          setCurrent(Step.ONE);
          form.resetFields();
          onCancel?.();
        }}
      />
      <div className={classNames('text-[12px] w-[100%] text-[#1D2129] leading-[24px]', { hidden: current !== Step.ONE })}>{locale['ai.writing.generate.feedback.modal.title']}</div>
      <Form
        form={form}
        autoComplete="off"
        labelCol={{
          flex: 'auto 0',
        }}
        wrapperCol={{
          flex: 'auto',
        }}
        size="large"
        labelAlign="left"
      >
        <Form.Item
          className={classNames('mb-0 text-[#1D2129] font-medium', { hidden: current !== Step.ONE })}
          label={locale['ai.writing.generate.feedback.modal.lable.one']}
          field={'one'}
        >
          <Rate onChange={() => setCurrent(Step.TWO)} />
        </Form.Item>
        <Form.Item
          className={classNames('mb-0 text-[#1D2129] font-medium', { hidden: current !== Step.TWO })}
          label={locale['ai.writing.generate.feedback.modal.lable.two']}
          field={'two'}
        >
          <Rate onChange={() => setCurrent(Step.THREE)} />
        </Form.Item>
        <Form.Item
          className={classNames('mb-0 text-[#1D2129] font-medium', { hidden: current !== Step.THREE })}
          label={locale['ai.writing.generate.feedback.modal.lable.three']}
          field={'three'}
        >
          <Rate onChange={() => setCurrent(Step.FOUR)} />
        </Form.Item>
        <Form.Item className={classNames('mb-0 text-[#1D2129] font-medium', { hidden: current !== Step.FOUR })}>
          <Grid.Row align="end">
            <Grid.Col flex="1">
              <Form.Item field="remark" className="mb-0">
                <Input.TextArea className="min-h-[60px] bg-white border-[#E5E6EB] border-1" placeholder={locale['report.modal.content.placeholder']} />
              </Form.Item>
            </Grid.Col>
            <Grid.Col flex="0">
              <Button
                className="ml-[16px] h-[32px]"
                type={feedBackModalIsConfirm ? 'primary' : 'secondary'}
                disabled={!feedBackModalIsConfirm}
                onClick={async () => {
                  try {
                    const formValues = await form.validate();
                    if (formValues.remark) {
                      reportConfirm(formValues);
                    } else {
                      onOk?.(formValues);
                    }
                  } catch (error) {}
                }}
              >
                {locale['ai.writing.generate.feedback.modal.confirm']}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FeedbackComp;
