import './index.scss';

import { Input, Select } from '@arco-design/web-react';
import { FC, useState } from 'react';
import AttachmentToolbar from '@components/attachment-toolbar';
import { WriteEssayParam } from '@/store/editorStore';
import useLocale from '@/hooks/useLocale';
import { Resource } from '@/components/upload-modal';

const { Option } = Select;

export enum ParamKeys {
  LANG = 'lang',
  WORD_NUM = 'wordNum',
}

interface AiInputToolbarProps {
  writeEssayParam?: WriteEssayParam;
  onChange?: (value: string, key: ParamKeys) => void;
  onAttachmentChange?: (files?: Resource[], links?: Resource[]) => void;
}

const AiInputToolbar: FC<AiInputToolbarProps> = ({ writeEssayParam, onChange, onAttachmentChange }) => {
  const languageOpts = [
    { label: 'ai.writing.translate.english', value: 'English' },
    { label: 'ai.writing.translate.simplified', value: '简体' },
    { label: 'ai.writing.translate.traditional', value: '繁體' },
  ];
  const textCountOpts = [100, 500, 1000, 2000];
  const locale = useLocale();

  const [countOpts, setCountOptions] = useState(textCountOpts);

  const [inputValue, setInputValue] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);

  const onInputChange = (value: string) => {
    setInputValue(() => value.replace(/[^\d]/g, ''));
  };

  const onPressEnter = () => {
    if (inputValue && countOpts.indexOf(Number(inputValue)) === -1) {
      setCountOptions(oldState => [...oldState, Number(inputValue)]);
      setInputValue(() => '');
    }
    setPopupVisible(() => false);
    onChange?.(inputValue, ParamKeys.WORD_NUM);
  };

  const onVisibleChange = (visible: boolean) => {
    setPopupVisible(() => visible);
  };

  return (
    <div className="ai-input-toolbar">
      <AttachmentToolbar onChange={(files, links) => onAttachmentChange?.(files, links)} />
      <div className="flex" style={{ gap: '10px' }}>
        <Select
          bordered
          dropdownMenuClassName="word-num-dropdown-menu"
          defaultValue={writeEssayParam?.wordNum || locale['inputBox.settings.wordNum.text']}
          onChange={(value: string) => {
            onChange?.(value, ParamKeys.WORD_NUM);
          }}
          key={writeEssayParam?.wordNum}
          className="ai-select"
          dropdownRender={menu => (
            <div className="dropdown-render">
              {menu}
              <Input
                className="input-word-num"
                placeholder={locale['inputBox.settings.input.placeholder']}
                value={inputValue}
                onChange={onInputChange}
                onPressEnter={onPressEnter}
              />
            </div>
          )}
          triggerProps={{ className: 'select-trigger-popup', popupVisible, onVisibleChange }}
        >
          {countOpts.map(item => {
            return <Option value={item} key={item} />;
          })}
        </Select>
        <Select
          bordered
          onChange={(value: string) => {
            onChange?.(value, ParamKeys.LANG);
          }}
          defaultValue={writeEssayParam?.lang || 'English'}
          key={writeEssayParam?.lang}
          className="ai-select"
          triggerProps={{ autoAlignPopupMinWidth: true, autoAlignPopupWidth: false }}
        >
          {languageOpts.map(({ label, value }) => {
            return (
              <Option value={value} key={value}>
                {locale[label]}
              </Option>
            );
          })}
        </Select>
      </div>
    </div>
  );
};

export default AiInputToolbar;
