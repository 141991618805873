import { FC, ReactNode, useMemo } from 'react';

import classNames from 'classnames';
import { Tooltip } from '@arco-design/web-react';
import styles from './index.module.scss';
import { SelectedMarks } from '@/type/toolsbar';
import useLocale from '@/hooks/useLocale';

const DefaultMenu: FC<{
  children: ReactNode;
  menuKey: string;
  selectedMarks: SelectedMarks;
  onSelect: (key: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
}> = ({ children, menuKey, onSelect, selectedMarks = {}, disabled = false }) => {
  const locale = useLocale();
  const active = useMemo(() => {
    if (Object.prototype.hasOwnProperty.call(selectedMarks, menuKey)) {
      return true;
    }
    return false;
  }, [selectedMarks, menuKey]);
  return (
    <Tooltip content={locale[menuKey]} disabled={!locale[menuKey] || disabled}>
      <div
        className={classNames(styles.defaultMenuContainer, { [styles.defaultMenuContainerActive]: active, [styles.defaultMenuContainerDisabled]: disabled })}
        onMouseDown={e => e.preventDefault()}
        onClick={e => {
          if (disabled) {
            return;
          }
          onSelect(menuKey, e);
        }}
      >
        <div className={styles.defaultMenuInner}>{children}</div>
      </div>
    </Tooltip>
  );
};
export default DefaultMenu;
